
import {useState, useEffect, useContext} from 'react';

import MappaInserisciComp from "./InserimentoRichiestaComponents/MappaInserisci.js"
import TipoRichiedenteComp from "./InserimentoRichiestaComponents/TipoRichiedente.js"
import TipoInterventoComp from "./InserimentoRichiestaComponents/TipoIntervento.js"
import OggettoRichiestaComp from "./InserimentoRichiestaComponents/OggettoRichiesta.js"
import StatoRichiestaComp from "./InserimentoRichiestaComponents/StatoRichiesta.js"
import DannoComp from "./InserimentoRichiestaComponents/Danno.js"
import PriorityComp from "./InserimentoRichiestaComponents/Priority.js"
import MezziComponent from "./InserimentoRichiestaComponents/MezziComponent.js"

import CausaAnnullamentoComp from "./InserimentoRichiestaComponents/CausaAnnullamento.js"

import { UserContext } from "../Context/UserContext.js";
import { SideMenuContext } from "../Context/SidebarContext.js";
import "../style/InserimentoRichiesta.css"
import baseUrl from '../utilities/globalVariables.js'
import {useHistory} from "react-router-dom";

import Footer from "./Footer.js"

function InserimentoRichiesta(){

    const userObject = useContext(UserContext);
    const {setUsername, userId, setUserId, setRuolo, accessToken, setAccessToken, setCapoSquadra} = userObject

    const sideMenuContext =  useContext(SideMenuContext);
    const openSidemenu = sideMenuContext.openSidemenu;

    let history = useHistory();
    const apiUrl= baseUrl + "/api/v1/richieste";

    if(accessToken == null){
        //console.log("LOCAL",window.localStorage)
        if(window.localStorage.accessToken){
            setRuolo({id:window.localStorage.ruoloId, name: window.localStorage.ruoloName}); //sono già stringhe
            setUsername(JSON.parse(window.localStorage.username));
            setAccessToken(JSON.parse(window.localStorage.accessToken));
            setUserId(window.localStorage.userId); //sono già stringhe
            setCapoSquadra(window.localStorage.caposquadra)
        } else {
            history.push ("/")
        }
    }

    //stati che passerò ai components (campi select che faranno una get)e che saranno settati durante la compilazione del form
    const [tipoRichiedente, setTipoRichiedente]= useState(1);
    const [tipoIntervento, setTipoIntervento]= useState(5);
    const [oggettoRichiesta, setOggettoRichiesta]= useState(1);
    const [statoRichiesta, setStatoRichiesta]= useState(1);
    const [danno, setDanno]= useState(5);
    const [priority, setPriority]= useState(3);
    const [causaAnnullamento, setCausaAnnullamento]= useState(null);
    const [idMezzoScelto, setIdMezzoScelto]= useState([]);

    //stati testuali del form
    const [geom, setGeom]= useState('');
    const [idLocalita, setIdLocalita]= useState('');
    const [nomeLocalita, setNomeLocalita]= useState('');
    const [indirizzo, setIndirizzo]= useState('');
    const [municipio, setMunicipio]= useState('');
    const [civico, setCivico]= useState(''); //RESTA NULL
    const [idAlbero, setIdAlbero]= useState(null);

    const [idDuplicata, setIdDuplicata]= useState(null);
    const [noteOggetto, setNoteOggetto]= useState('');
    const [noteIntervento, setNoteIntervento]= useState('');
    const [dataRisoluzione, setDataRisoluzione]= useState(null);
    const [foto, setFoto] = useState(null);
    const [interferenza, setInterferenza]= useState('false');
    const [noteInterferenza, setNoteInterferenza]= useState(false);
    const [testoNoteInterferenza, setTestoNoteInterferenza]= useState('');

    //interni al component
    const [avviso, setAvviso]= useState({text:'', className:''});

    //stati per render condiziato alle scelte
    const [idDuplicataInput, setIdDuplicataInput]= useState(false);
    const [causaAnnullamentoInput, setCausaAnnullamentoInput]= useState(false);
    const [noteOggettoInput, setNoteOggettoInput]= useState(false);
    const [dataRisoluzioneInput, setDataRisoluzioneInput]= useState(false);

    //questi 4 useEffect mi servono per aprire dei campi condizionati alle scelte dell'utente (es Se l'oggetto è altro allora si apre il div note oggetto!)
    useEffect(()=>{ //se ANNULLATA APRE CAUSA ANNULLAMENTO
        if(statoRichiesta==7){
            setCausaAnnullamentoInput(true)
        }else{
            setCausaAnnullamentoInput(false)
            setCausaAnnullamento(null)
            setIdDuplicataInput(false)
            setIdDuplicata(null)
        }
    },[statoRichiesta])

    useEffect(()=>{ //SE CAUSE = DUPLICATA APRE INSERISCI ID DUPLICATA
        if(causaAnnullamento==3){
            setIdDuplicataInput(true)
        }else{
            setIdDuplicataInput(false)
            setIdDuplicata(null)
        }
    },[causaAnnullamento])

    useEffect(()=>{ //SE RICHIESTA RISOLTA APRE DATA RISOLUZIONE
        if(statoRichiesta==5){
            setDataRisoluzioneInput(true)
        }else{
            setDataRisoluzioneInput(false)
            setDataRisoluzione(null)
            /* setIdDuplicataInput(false) */
        }
    },[statoRichiesta])

    useEffect(()=>{ //SE OGGETTO è ALTRO APRE NOTE OGG
        if(oggettoRichiesta==3){
            setNoteOggettoInput(true)
        }else{
            setNoteOggettoInput(false)
            setNoteOggetto('')
        }
    },[oggettoRichiesta])

    useEffect(()=>{
        if(interferenza=='true'){
            setNoteInterferenza(true)
        }else{
            setNoteInterferenza(false)
        }
    }, [interferenza])


/*  PARTE RELATIVA ALLA RICERCA DI UNA RICHIESTA GIA ESSTENTE */
    const [alreadyExists, setAlreadyExists] = useState(false)
    const [numeroEsistenti, setNumeroEsistenti] = useState(0)

    useEffect(()=>{ //appena nomeLocalità cambia (da dentro mappa inserisci) viene chiamata la f(x) 
        if(nomeLocalita=="" && idLocalita==''){
            setAlreadyExists(false)
        }else{
            checkIfAlreadyExists()
        }
    },[nomeLocalita, idLocalita])

    function checkIfAlreadyExists(){
        console.log('chiamata')
        //se l'interrogazione dei livelli ha portato un risultato, viene effettuato un controllo su tutte le richieste già inserite 
        //per mostrare se già ne esistono in quello specifico NOME località

        //GET INIZIALE DI TUTTE LE RICHIESTE non deleted e non stored
        var myHeaders = new Headers();        
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("x-user-id", userId);
        myHeaders.append('x-access-token', accessToken);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        
        fetch(apiUrl, requestOptions)
        .then(response => response.json())
        .then(result =>{ 
            //console.log(result);
            if(result.message){
                filterByIdONomeLocalita(result.message)
            }else if (result.error){
                setAlreadyExists(false)
            }
        })
        .catch(error => console.log('error', error)); 
    }

    function filterByIdONomeLocalita(richieste){
        var arrayIdEsistenti=[]
        richieste.forEach(richiesta=>{
            if(richiesta.nomeLocalità == nomeLocalita || richiesta.idLocalità == idLocalita){
                arrayIdEsistenti.push(richiesta.id)
            }
        })
        if(arrayIdEsistenti.length==0){
            setAlreadyExists(false)
        }else{//arrayIdEsistenti.length>0
            setAlreadyExists(true)
            setNumeroEsistenti(
                arrayIdEsistenti.map((id, i)=>{
                    if(i==arrayIdEsistenti.length - 1){ //se ne esiste solo uno
                        return <span className="idRichiestaEsistente" id={id} key={id}  onClick={(e)=>clickedNumber(e)}>{id}</span>
                    }
                    else{
                        return <div key={id} style={{display:'inline-block'}}><span  className="idRichiestaEsistente" id={id} onClick={(e)=>clickedNumber(e)} >{id}</span><span> -</span></div>
                    }
                })
                )
        }
    }

    //con questa funzione passo l'id della cliccata come prop alla pagina dopo
    function clickedNumber(e){
        history.push({
            pathname: '/singolarichiesta',
            state: { idRichiesta: e.target.id}
        });
    }

    /* PARTE RELATIVA ALL INSERIMENTO DI UNA NUOVA RICHIESTA */
    
    function controlliPreCreazione(){
        /* Per i campi obbligatori: se passa tutti i controlli chiami la f(x) */
        if(geom==null || geom==''){
            setAvviso({text:"È obbligatorio selezionare un campo LatLng ( Clicca su mappa o cerca l'indirizzo)", className:'red'})
            return
        }
        if (foto){
            var arrayFoto = Array.from(foto)
            if (arrayFoto.some(pic => pic.type != "image/png" && pic.type != "image/jpg" && pic.type != "image/jpeg")){
                setAvviso({text:"Il file selezionato non è di tipo .png, .jpg o .jpeg!", className:'red'})
                return
            }
        }
        creaNuovaRichiesta()
    }

    function creaNuovaRichiesta(){
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("x-user-id", userId);
        myHeaders.append('x-access-token', accessToken);

        var urlencoded = new URLSearchParams();
        urlencoded.append("geom", geom); //obbligo inserimento valutato prima in controlliPreCreazione
        urlencoded.append("idLocalita", idLocalita); //NON obbligato nè FE ne BE =>può restare vuoto?
        urlencoded.append("nomeLocalita", nomeLocalita); //NON obbligato nè FE ne BE =>può restare vuoto?
        urlencoded.append("indirizzo", indirizzo); //NON obbligato nè FE ne BE =>può restare vuoto?
        urlencoded.append("mezziId", idMezzoScelto); //obbligo inserimento valutato prima in controlliPreCreazione
        urlencoded.append("createdByUserId", userId);

        urlencoded.append("noteOggetto", noteOggetto); 
        urlencoded.append("noteIntervento", noteIntervento); 
        urlencoded.append("interferenza", interferenza); 
        urlencoded.append("testoNoteInterferenza", testoNoteInterferenza); 

        if(municipio){
            urlencoded.append("municipio", municipio); //non è obbligato, ma se UNDEFINED???
        }
        if(civico){
            urlencoded.append("civico", civico); //non è obbligato, ma se null mando una stringa e non la vuole :D
        }
        if(tipoRichiedente){
            urlencoded.append("tipoRichiedente", tipoRichiedente); //obbligato solo BE => se non inserito ha il default value
        }
        if(oggettoRichiesta){
            urlencoded.append("oggettoRichiesta", oggettoRichiesta); //obbligato solo BE => se non inserito ha il default value
        }
        if(priority){
            urlencoded.append("priorities", priority); //obbligato solo BE => se non inserito ha il default value
        }
        if(danno){
            urlencoded.append("danni", danno); //obbligato solo BE => se non inserito ha il default value
        }
        if(tipoIntervento){
            urlencoded.append("tipoIntervento", tipoIntervento); //obbligato solo BE => se non inserito ha il default value
        }
        if(statoRichiesta){ 
            urlencoded.append("statoRichiesta", statoRichiesta); //obbligato solo BE => se non inserito ha il default value
        }
        if(causaAnnullamento){
            urlencoded.append("causaAnnullamento", causaAnnullamento); //obbligato solo BE => se non inserito ha il default value
        }

        if(idDuplicata){ 
            // questo significa che se è =='' (es inserisco e ricancello) viene mandata in BE 
            //se invece è null non verrà proprio mandata
            urlencoded.append("idRichiestaDuplicata", idDuplicata); 
        }
        if(idAlbero){
            // questo significa che se è =='' (es inserisco e ricancello) viene mandata in BE 
            //se invece è null non verrà proprio mandata
            urlencoded.append("idAlbero", idAlbero);
        }

        if(dataRisoluzione){
            urlencoded.append("dataRisoluzione", dataRisoluzione); 
        }

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
        };

        fetch(baseUrl + "/api/v1/richieste", requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result.message){
                //console.log(result);
                setAvviso({text:result.message, className:'green'})
                if (foto) {
                    inserimentoImmagine(result.richiestaId)
                }
                puliziaCampiPostReq();
            }else if (result.error){
                setAvviso({text:result.error, className:'red'})
            }
        })
        
        .catch(error => console.log('error', error));
    }

/*  PARTE RELATIVA ALLE IMMAGINI */
    function handleInputImages(e){
        //console.log(e.target.files)
        setFoto(e.target.files)
    }

    function inserimentoImmagine(richiestaId){
        //console.log(richiestaId)
        var myHeaders = new Headers();
        myHeaders.append("x-user-id", userId);
        myHeaders.append('x-access-token', accessToken);

        const formData = new FormData();
        formData.append("richiestaId",richiestaId);
        formData.append("tipoImmagine", 1); //l'operatore invece potrà inserire solo il PRIMA e il DOPO 

        for (let i = 0; i < foto.length; i++) {
            formData.append("images", foto[i]);
        }

        var requestOptions = {
            method: 'POST',
            headers:myHeaders,
            body: formData,
            redirect: 'follow'
        };
        
        fetch(baseUrl + "/api/v1/immagini", requestOptions)
        .then(response => response.json())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));
    }

    function puliziaCampiPostReq(){
        //console.log("pulisco campi post req");
        setGeom('');
        setIdLocalita('');
        setNomeLocalita('');
        setMunicipio('')
        setIndirizzo('');
        setCivico('');
        setTipoRichiedente(1);
        setOggettoRichiesta(1);
        setNoteOggetto('');
        setPriority(3);
        setDanno(null);
        setTipoIntervento(5);
        setNoteIntervento('');
        setIdMezzoScelto([]);
        setStatoRichiesta(1);
        setCausaAnnullamento(null);
        setIdDuplicata(null);
        setDataRisoluzione(null);
        setFoto('');
        setIdAlbero('');
        setInterferenza('false')
        setTestoNoteInterferenza('')
    }

    return(
        <div className={`main ${openSidemenu?"":"mainChiuso"}`}> {/*  mainChiuso  */}
            <div className="contenutoMain">
                <MappaInserisciComp 
                    setGeom={setGeom}  
                    setIdLocalita={setIdLocalita} 
                    setNomeLocalita={setNomeLocalita} 
                    setIndirizzo={setIndirizzo} 
                    setMunicipio={setMunicipio} 
                    setCivico={setCivico} 
                    setIdAlbero={setIdAlbero}
                ></MappaInserisciComp>
                <div className="formInserimentoRichiesta">

                    {alreadyExists&&<div style={{marginBottom:'2vh'}}>In questa località (o id località) sono già presenti alcune richieste. (ID: {numeroEsistenti} )</div>}

                    <p className="titoloformInserimentoRichiesta">INSERISCI NUOVA RICHIESTA</p>

                    <div className="formInserimentoRichiestaLeft">
                        <p className="titoloDatiContainer">Dati Geografici</p>
                        <div className="datiGeograficiContainer">

                            <div className="campoInserimentoRichiesta">
                                <label>Latitudine, Longitudine </label>
                                <input  type="text" value={geom} onChange={(e) => setGeom(e.target.value)} disabled="disabled"></input>
                            </div>
                            <div className="campoInserimentoRichiesta">
                                <label>Id Località</label>
                                <input  type="text" value={idLocalita} onChange={(e) => setIdLocalita(e.target.value)}></input>
                            </div>
                            <div className="campoInserimentoRichiesta">
                                <label>Nome Località</label>
                                <input  type="text" value={nomeLocalita} onChange={(e) => setNomeLocalita(e.target.value)}></input>
                            </div>
                            <div className="campoInserimentoRichiesta">
                                <label>Municipio</label>
                                <input  type="text" value={municipio} onChange={(e) => setMunicipio(e.target.value)}></input>
                            </div>
                            <div className="campoInserimentoRichiesta">
                                <label>Indirizzo</label>
                                <input  type="text" value={indirizzo} onChange={(e) => setIndirizzo(e.target.value)}></input>
                            </div>
                            <div className="campoInserimentoRichiesta">
                                <label>Civico</label>
                                <input  type="varchar(6)" value={civico} onChange={(e) => setCivico(e.target.value)}></input>
                            </div>
                        </div>
                    </div>
                    <div className="formInserimentoRichiestaRight">
                        <p className="titoloDatiContainer">Dati Generali</p>
                        <div className="datiGeneraliContainer">
                            <TipoRichiedenteComp tipoRichiedente={tipoRichiedente} setTipoRichiedente={setTipoRichiedente}></TipoRichiedenteComp>
                            <PriorityComp priority={priority} setPriority={setPriority}></PriorityComp>
                            <OggettoRichiestaComp oggettoRichiesta={oggettoRichiesta} setOggettoRichiesta={setOggettoRichiesta}></OggettoRichiestaComp>

                            {noteOggettoInput&&<div className="campoInserimentoRichiesta">
                                <label style={{fontWeight:'500'}}>Note Oggetto</label>
                                <textarea maxLength="150" value={noteOggetto} onChange={(e) => setNoteOggetto(e.target.value)}></textarea>
                                <span className="smallerFont"> Inserire dettagli sull'oggetto della richiesta. </span>
                            </div>}
                            <div className="campoInserimentoRichiesta">
                                <label>Id Albero</label>
                                <input  type="number" value={idAlbero} onChange={(e) => setIdAlbero(e.target.value)}></input>
                            </div>
                            <div className="campoInserimentoRichiesta">
                                <label>Interferenza</label>
                                <select onChange={(e)=>setInterferenza(e.target.value)} value={interferenza}  /* disabled={editor?'disabled':''} */>
                                    <option value="false">No</option>
                                    <option value="true" >Si</option>
                                </select>
                            </div>
                            {noteInterferenza&&<div className="campoInserimentoRichiesta">
                                <label style={{fontWeight:'500'}}>Note Interferenza</label>
                                <textarea maxLength="150" value={testoNoteInterferenza} onChange={(e) => setTestoNoteInterferenza(e.target.value)}></textarea>
                                <span className="smallerFont"> Inserire eventuali dettagli sull'interferenza. </span>
                            </div>}
                            <DannoComp danno={danno} setDanno={setDanno}></DannoComp>

                            
                            <TipoInterventoComp tipoIntervento={tipoIntervento} setTipoIntervento={setTipoIntervento}></TipoInterventoComp>
                            <div className="campoInserimentoRichiesta">
                                <label style={{fontWeight:'500'}}>Note Intervento</label>
                                <textarea maxLength="150" value={noteIntervento} onChange={(e) => setNoteIntervento(e.target.value)}></textarea>
                                <span className="smallerFont"> Inserire dettagli sull'intervento da effettuare. </span>
                            </div>
                            <MezziComponent idMezzoScelto={idMezzoScelto} setIdMezzoScelto={setIdMezzoScelto}></MezziComponent>
                            <StatoRichiestaComp  statoRichiesta={statoRichiesta} setStatoRichiesta={setStatoRichiesta}></StatoRichiestaComp>

                            {causaAnnullamentoInput&&<CausaAnnullamentoComp causaAnnullamento={causaAnnullamento} setCausaAnnullamento={setCausaAnnullamento}></CausaAnnullamentoComp>}
                            {idDuplicataInput&&<div className="campoInserimentoRichiesta">
                                <label>Id Duplicata</label>
                                <input  type="number" value={idDuplicata} onChange={(e) => setIdDuplicata(e.target.value)}></input>
                            </div>}
                            {dataRisoluzioneInput &&<div className="campoInserimentoRichiesta">
                                <label>Data Risoluzione</label>
                                <input  type="date"  value={dataRisoluzione} onChange={(e) => setDataRisoluzione(e.target.value)} ></input>
                            </div>}
                        </div>
                    </div>

                    <div className="insertImages">
                        <label style={{display:'inline'}}>Aggiungi Immagini: </label>
                        <input type="file" id="imagesInput" accept="image/*" name="images" aria-label="File browser" multiple onChange={(e)=>handleInputImages(e)}></input>
                        {/* <div id="fileNumbers">0 file selezionati</div> */}
                        {/* <div id="spinner"><i class="spinner fas fa-spinner"></i></div> */}
                    </div>
                </div>
                
                    <div className="containerBottoneInserisci">
                        <div className="bottoneConferma" onClick={()=>controlliPreCreazione()}>INSERISCI</div>
                        <p className={`avviso ${avviso.className}`}>{avviso.text}</p>
                        </div> 
                    
                

            </div>
            <Footer></Footer>
        </div>
    )
}
export default InserimentoRichiesta;

