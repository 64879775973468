

/* 
RICORDA DI FARE CONTROLLI RUOLO BE SULLE CHIAMATE ALLE RICHIESTE ARCHIVIATE!! SOLO SE SUPERADMIN!   
*/

/* componente padre che permette di passare dallo storico emergenze all'archivio richieste relative ad un evento singolo 
 è una pagina che appare solo se il ruolo è user o superadmin.

*/
import  React, { useState , useContext  } from 'react'


import { UserContext } from "../Context/UserContext.js";
import { SideMenuContext } from "../Context/SidebarContext.js";
import {useHistory} from "react-router-dom"; 

import ArchivioRichieste from "./StoricoComponents/ArchivioRichieste.js"
import StoricoEmergenze from "./StoricoComponents/StoricoEmergenze.js"
import Footer from "./Footer.js"
import "../style/StoricoPage.css"

export function StoricoPage(){
    let history = useHistory();

    const userObject = useContext(UserContext);
    const {setUsername,  setUserId,  setRuolo, accessToken, setAccessToken, setCapoSquadra} = userObject

    const sideMenuContext = useContext(SideMenuContext);
    const openSidemenu = sideMenuContext.openSidemenu;
    const [renderArchivio, setRenderArchivio] = useState(false)
    const [eventoConclusoScelto, setEventoConclusoScelto] = useState({dataInizio:'',dataConclusione:''}) //EPOCH

    if(accessToken == null){
        //console.log("LOCAL",window.localStorage)
        if(window.localStorage.accessToken){
            setRuolo({id:window.localStorage.ruoloId, name: window.localStorage.ruoloName}); //sono già stringhe
            setUsername(JSON.parse(window.localStorage.username));
            setAccessToken(JSON.parse(window.localStorage.accessToken));
            setUserId(window.localStorage.userId); //sono già stringhe
            setCapoSquadra(window.localStorage.caposquadra)
        } else {
            history.push ("/")
        }
    }


    return(
        <div className={`main ${openSidemenu?"":"mainChiuso"}`}> {/*  mainChiuso  */}
            <div className="contenutoMain">
                {!renderArchivio&&<StoricoEmergenze setRenderArchivio={setRenderArchivio} eventoConclusoScelto={eventoConclusoScelto} setEventoConclusoScelto={setEventoConclusoScelto}></StoricoEmergenze> }
                {renderArchivio&&<ArchivioRichieste setRenderArchivio={setRenderArchivio} eventoConclusoScelto={eventoConclusoScelto} setEventoConclusoScelto={setEventoConclusoScelto}></ArchivioRichieste>}
            </div> 
            <Footer></Footer>
        </div> 
    )
}

export default StoricoPage
