/* questo component conterrà solo la label e la select della lista SQUADRE LOGGATE!!!!!, gli passerò come prop il setter dal component inserimento richiesto */
import {useState, useContext, useEffect} from 'react';

import { UserContext } from "../../Context/UserContext.js";
import baseUrl from '../../utilities/globalVariables.js'


function MezziLoggate(props){
    const [ listaMezzi, setListaMezzi] = useState([]);

    const [ idMezzoScelto, setIdMezzoScelto] = [props.idMezzoScelto, props.setIdMezzoScelto]; //OGGETTO!!!!
    const userObject = useContext(UserContext);
    const { userId, accessToken, ruolo} = userObject

    //per render condizionato ai ruoli
    var editor = ruolo.id==2?true:false;


    //onload faccio get a lista tipi per popolare la select ma PRIMA manipolo il risultato
    useEffect(()=>{
        if(accessToken==null){return}
        
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("x-user-id", userId);
        myHeaders.append('x-access-token', accessToken);

        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };
        fetch(baseUrl + "/api/v1/mezzi ", requestOptions)
        .then(response => response.json())
        .then(result => {
            //console.log("lista Mezzi db", result.message)
            if(result.message != undefined){
                //manipolo per togliere i deleted e ordinare i logged

                let arrayModificato=[];
                result.message.map((item)=>{
                if(item.deleted==false){
                    arrayModificato.push(item)
                    }
                })
                arrayModificato.sort( (a,b)=>{
                    return b.user.logged - a.user.logged
                })
                //console.log("array filtrato e ordinato Mezzi", arrayModificato)
                setListaMezzi(arrayModificato)
                //setIdMezzoScelto(idMezzoScelto.map((item)=>{}))
            }
        })
        .catch(error => console.log('error', error));
    },[accessToken])

    function handleChange(e){ 
        let value = Array.from(e.target.selectedOptions, option => option.value);
        setIdMezzoScelto(value);
        //console.log("idMezzoScelto inizio mezzicomponent", idMezzoScelto);
    }
    
    return(
        <>
        {!editor &&<div className="campoInserimentoRichiestaMEZZI">
            <label>Mezzo da Assegnare**</label>
            <select  multiple={true} onChange={(e)=>handleChange(e)} size='6' /* value={idMezzoScelto} */>
                <option value={[]}>---</option>
                {listaMezzi.map((item)=>{
                    //console.log("ITEM", item)
                    let loggata;
                    if(item.user.logged == true){
                        loggata = "Disponibile"
                    } else {
                        loggata = "Non Disponibile"
                    }
                    return <option key={item.id} value={item.id} > {loggata}  - {item.user.username} - {item.targaMezzo} -- {item.tipoMezzo} </option>
                    })}
            </select>
            <span  className="smallerFont">**Si possono scegliere più mezzi contemporaneamente, cliccando mentre si tiene premuto il tasto CTRL(Win) o COMMAND(Mac)</span>
        </div>}
        </>
    )
}
export default MezziLoggate;
