import  React, { useState , useEffect, useContext  } from 'react'


import { UserContext } from "../../Context/UserContext.js";
import baseUrl from '../../utilities/globalVariables.js'
import Table from "../RicercaRichiestaComponents/Table.js"
import FiltriDate from "../RicercaRichiestaComponents/FiltriDate.js"
import {useHistory} from "react-router-dom"; 

import "../../style/Archivio.css"

import { faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
    }) {
        // Calculate the options for filtering
        // using the preFilteredRows
        const options = React.useMemo(() => {
        const options = new Set()
        preFilteredRows.forEach(row => {
            options.add(row.values[id])
        })
        return [...options.values()]
        }, [id, preFilteredRows])
    
        // Render a multi-select box
        return (
        <select
            value={filterValue}
            onChange={e => {
            setFilter(e.target.value || undefined)
            }}
        >
            <option value="">All</option>
            {options.map((option, i) => (
            <option key={i} value={option}>
                {option}
            </option>
            ))}
        </select>
    )
}

export function ArchivioRichieste(props){

    var emergencyURL = baseUrl + "/api/v1/richieste/emergenza";
    var emergenzaPage = true; //con questa var (che passo a table che passa a singola richiesta) andrò a dire che se true le singole richieste non saranno modificabili
    let history = useHistory();

    const userObject = useContext(UserContext);
    const {setUsername, userId, setUserId, ruolo, setRuolo, accessToken, setAccessToken, setCapoSquadra} = userObject
    const [avviso, setAvviso] = useState(false)
    const {setRenderArchivio, eventoConclusoScelto, setEventoConclusoScelto} = props;

    //eventoConclusoScelto è un EPOCH
    const inizio = new Date(+eventoConclusoScelto.dataInizio).toLocaleString()
    const fine = new Date(+eventoConclusoScelto.dataConclusione).toLocaleString()

    const [listaRichieste, setListaRichieste] = useState([]);
    const [listaRichiesteFiltrate, setListaRichiesteFiltrate] = useState([]) //stato di appoggio della lista effettiva, serve per non perdere i risultati dopo una ricerca
    const [loading, setLoading] = useState(false);

    var user= ruolo.id==1? true:false; //per render condizionato ai ruoli

    if(accessToken == null){
        //console.log("LOCAL",window.localStorage)
        if(window.localStorage.accessToken){
            setRuolo({id:window.localStorage.ruoloId, name: window.localStorage.ruoloName}); //sono già stringhe
            setUsername(JSON.parse(window.localStorage.username));
            setAccessToken(JSON.parse(window.localStorage.accessToken));
            setUserId(window.localStorage.userId); //sono già stringhe
            setCapoSquadra(window.localStorage.caposquadra)
        } else {
            history.push ("/")
        }
    }
    //GET INIZIALE DI TUTTE LE RICHIESTE
    useEffect(()=>{
        if(accessToken == null){return}

        setLoading(true)

        var myHeaders = new Headers();        
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("x-user-id", userId);
        myHeaders.append('x-access-token', accessToken);
        myHeaders.append('x-data-inizio', eventoConclusoScelto.dataInizio);
        myHeaders.append('x-data-conclusione', eventoConclusoScelto.dataConclusione);


        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        /* QUI MANCA LA PARTE IN CUI DALLO STORICO EMERGENZA VIENE SCELTO UN EVENTO == MANDO 2 DATE
            CON QUESTE DATE FACCIO UNA GET WHERE CREATEDAT IS BETWEEN 
        */
        
        fetch(emergencyURL, requestOptions)
            .then(response => response.json())
            .then(result =>{ 
                setLoading(false)

            //console.log("YO",result);
            if(result.message&&result.message.length>0){
                setListaRichieste(result.message); 
                setListaRichiesteFiltrate(result.message); 
                setAvviso('')

            }else if (result.message.length == 0){
                setAvviso("Nessuna richiesta per l'intervallo selezionato.")

            }else if(result.error){
                setAvviso(result.error)
            }
        })
        .catch(error => console.log('error', error)); 
    },[accessToken])

    
    var columns = [
        {
            Header: 'Data Creazione',
            accessor: (row) =>{
                    if(row.createdAt){
                        row = row.createdAt.split('T')[0].split(`-`).reverse().join(`-`);
                        return row;
                }
            },
            disableFilters: true,
        },
        {
            Header: 'Id Richiesta',
            accessor: (row)=>{
                if(row.id){
                    return row.id}},
        },
        {
            Header: 'Id Località',
            accessor: (row)=>{
                if(row.idLocalità){
                    return row.idLocalità
                }
            },
        }, 
        {
            Header: 'Nome Località',
            accessor: (row)=>{
                if(row.nomeLocalità){
                    return row.nomeLocalità
                }
            },
        },
        {
            Header: 'Municipio',
            accessor: (row)=>{
                if(row.municipio){
                    return row.municipio;
                }
            },
        },
        {
            Header: 'Indirizzo',
            accessor: (row)=>{
                if(row.indirizzo){
                    return row.indirizzo
                }
            },
        },
        {
            Header: 'Civico',
            accessor: (row)=>{
                if(row.civico){
                    return row.civico
                }
            },
        },
        {
            Header: 'Tipi Richiedente',
            accessor: (row)=>{
                if(row.tipirichiedente){
                    return row.tipirichiedente.name
                }
            },
            Filter: SelectColumnFilter

        },
        {
            Header: 'Oggetto',
            accessor: (row)=>{
                if(row.oggettirichiestum){
                    return row.oggettirichiestum.name
                }
            },
            Filter: SelectColumnFilter

        },
        {
            Header: 'Note Oggetto',
            accessor: (row)=>{
                if(row.noteOggetto){
                    return row.noteOggetto
                }
            },
        },
        {
            Header: 'Id Albero',
            accessor: (row)=>{
                if(row.idAlbero){
                    return row.idAlbero
                }
            },
        },
        {
            Header: 'Interferenza',
            accessor: (row)=>{
                if(row.interferenza){
                    return 'Si'
                }else{
                    return 'No'

                }
            },
        },
        {
            Header: 'Priorità',
            accessor: (row)=>{
                if(row.priority){
                    return row.priority.name
                }
            },
            Filter: SelectColumnFilter

        },
        {
            Header: 'Danni',
            accessor:   (row)=>{
                if(row.danni){
                    return row.danni.name
                }
            },
            Filter: SelectColumnFilter
        },
        {
            Header: 'Tipi Intervento',
            accessor:  (row)=>{
                if(row.tipiintervento){
                    return row.tipiintervento.name
                }
            },
            Filter: SelectColumnFilter

        },
        {
            Header: 'Note Intervento',
            accessor:  (row)=>{
                if(row.noteIntervento){
                    return row.noteIntervento
                }
            }
        },
        {
            Header: 'Capo Squadra Assegnato',
            accessor: (row)=>{
                
                let users='';
                if (row.mezzis &&row.mezzis.length>1){
                        row.mezzis.forEach(mezzo=>{
                            //console.log(users.includes(mezzo.user.username))
                            if(!users.includes(mezzo.user.username))
                                {users += mezzo.user.username +", "}
                        })
                    return users;
                } else if(row.mezzis &&row.mezzis.length==1){ 
                    return row.mezzis[0].user.username
                }
            }
        },

        {
            Header: 'Targa Mezzo Assegnato',
            accessor: (row)=>{
            
                let mezzi='';
                if (row.mezzis &&row.mezzis.length>1){
                        row.mezzis.forEach(mezzo=>{
                            mezzi +=mezzo.targaMezzo +", "
                        })
                    return mezzi;
                } else if(row.mezzis &&row.mezzis.length==1){ 
                    return row.mezzis[0].targaMezzo
                }
            }
        },
        {
            Header: 'Stato Richiesta',
            accessor: (row)=>{
                return row.statirichiestum.name
            },
            Filter: SelectColumnFilter

        },
        {
            Header: 'Causa Annullamento',
            accessor: (row)=>{
                return row.causeannullamento.name
            },
            Filter: SelectColumnFilter

        },
        {
            Header: 'Id Richiesta Duplicata',
            accessor: (row)=>{
                return row.idRichiestaDuplicata
            }
        },
        {
            Header: 'Data Risoluzione',
            accessor: (row) =>{
                    if(row.dataRisoluzione){
                        row = row.dataRisoluzione.split('T')[0].split(`-`).reverse().join(`-`);
                        return row;
                }
            },
            disableFilters: true,
        },
        {
            Header: 'Immagini Allegate',
            accessor: (row) =>{
                if(row.immaginis.filter(x => x.deleted==false).length != 0){
                    row = `Si: ${row.immaginis.filter(x => x.deleted==false).length}`
                    return row;
                }else{
                    row = 'No';
                    return row;
                }
            },
        },

    ].filter(columns => !user || columns.Header !== 'Capo Squadra Assegnato').filter(columns => !user || columns.Header !== 'Targa Mezzo Assegnato');
    

    return(
        <div>
            <div className="goToStoricoEmergenze">
                <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
                <span onClick={()=> {setEventoConclusoScelto({dataInizio:'',dataConclusione:''}); setRenderArchivio(false)}}> TORNA ALLO STORICO EMERGENZE</span>
            </div> 
            <p className="headEmergenzaPage"> 
            
                Questa è la sezione dedicata alle richieste ARCHIVIATE, relative all'
                evento : dal {inizio} al {fine}
            </p>

            <p>{avviso}</p>

            <FiltriDate listaRichieste={listaRichieste} listaRichiesteFiltrate={listaRichiesteFiltrate} setListaRichiesteFiltrate={setListaRichiesteFiltrate}></FiltriDate>
            <Table columns={columns} data={listaRichiesteFiltrate} emergenzaPage={emergenzaPage}/>
            {loading&&<div className="loaderContainer"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>}

        </div> 
    )
}

export default ArchivioRichieste
