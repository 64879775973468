
import {useState, useContext, useEffect} from 'react';
import { UserContext } from "../../Context/UserContext.js";

import { faTruckPickup } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import baseUrl from '../../utilities/globalVariables.js'


  function MostraMezzi(props){

    const [listaMezzi, setListaMezzi] = useState([]);
    const [avviso, setAvviso]= useState({text:'', className:''});
    const [loading, setLoading] = useState(false);

    const [avvisoModifica, setAvvisoModifica]= useState({text:'', className:''});
    const mezziURL = baseUrl + "/api/v1/mezzi"
    const utentiURL= baseUrl + "/api/v1/users/caposquadra";

    const userObject = useContext(UserContext);
    const { userId, accessToken} = userObject

    const renderAutomatico =  props.renderAutomatico;
    const setRenderAutomatico = props.setRenderAutomatico;

    /* PARTE PER MODIFICA */
    const [modificaMezzo, setModificaMezzo] = useState(false)
    const [mezzoDaModificare, setMezzoDaModificare] = useState({})
    const [targaMezzo, setTargaMezzo]= useState('');
    const [idMezzo, setIdMezzo]= useState('');
    const [idCapoSquadra, setIdCapoSquadra]= useState('');
    const [tipoMezzo, setTipoMezzo]= useState('');
    const [listaCapiSquadra, setListaCapiSquadra] = useState([]);

    /* ONMOUNT CHIAMA LISTAMEZZI E LISTACAPISQUADRA */
    useEffect(()=>{
      setLoading(true)
        //togliere spazi e mettere uppercase alla targa
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("x-user-id", userId);
        myHeaders.append('x-access-token', accessToken);
        
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
            };
            
        fetch(utentiURL, requestOptions)
        .then(response => response.json())
        .then(result => {
            //console.log(result.message)
            setLoading(false)

            if(result.message){
              setListaCapiSquadra(result.message)

                /* let arrayModificato=[];
                result.message.forEach((item)=>{
                if(item.roleId==2 && item.capoSquadraFlag==true){
                    arrayModificato.push(item)
                    }
                })
                //console.log("array filtrato capisquadre", arrayModificato)
                setListaCapiSquadra(arrayModificato) */
            }
            if(result.error){ setListaCapiSquadra([])}
        })
        .catch(error => console.log('error', error));

        fetch(mezziURL, requestOptions)
          .then(response => response.json())
          .then(result =>{ 
            //console.log(result);
            if(result.message){
              setListaMezzi(result.message); 
              setAvviso({text:'', className:''})
            }else if (result.error){
              setAvviso({text:result.error, className:'red'})
            } 
          })
          .catch(error => console.log('error', error)); 
    },[renderAutomatico, accessToken])
    //console.log(listaMezzi)

    function handleDelete(e){
      let idMezzo=e.target.parentNode.id;
      
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
      myHeaders.append("x-user-id", userId);
      myHeaders.append('x-access-token', accessToken);

      var urlencoded = new URLSearchParams();
      urlencoded.append("idMezzo", idMezzo);

      var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
      };

      fetch(baseUrl + "/api/v1/mezzi", requestOptions)
        .then(response => response.json())
        .then(result => {
          //console.log(result);
          setRenderAutomatico(!renderAutomatico)
        })
        .catch(error => console.log('error', error));
    }

    function controlliPreCreazione(){
      /* Per i campi obbligatori: se passa tutti i controlli chiami la f(x) */
      if( tipoMezzo==''||targaMezzo==''||idCapoSquadra==''){
          setAvvisoModifica({text:'Tutti i campi devono essere compilati!', className:'red'});
          return;
      }
      callAPI()
    }

    useEffect(()=>{
      setIdMezzo(mezzoDaModificare.id)
      setTargaMezzo(mezzoDaModificare.targaMezzo)
      setTipoMezzo(mezzoDaModificare.tipoMezzo)

    },[mezzoDaModificare])

    function callAPI(){        
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
      myHeaders.append("x-user-id", userId);
      myHeaders.append('x-access-token', accessToken);

      var urlencoded = new URLSearchParams();
      urlencoded.append("idMezzo", idMezzo);
      urlencoded.append("targaMezzo", targaMezzo);
      urlencoded.append("capoSquadraId", idCapoSquadra);
      urlencoded.append("tipoMezzo", tipoMezzo);

      var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
      };

      fetch(mezziURL, requestOptions)
      .then(response => response.json())
      .then(result => {
          if(result.message){
              setAvvisoModifica({text:'Mezzo modificato correttamente', className:'green'})
              setTargaMezzo('')
              setTipoMezzo('')
              setIdCapoSquadra('')
              setRenderAutomatico(!renderAutomatico)
              setModificaMezzo(false)
          }else if (result.error){
            setAvvisoModifica({text:result.error, className:'red'})
          }
      })
      .catch(error => console.log('error', error));
  }
    
    return(
      <>{modificaMezzo&& 
            <div className="inserimentoMezzoContainer"> 
                  <p>Per <b>modificare</b> il mezzo compila i seguenti campi:</p>
                  
                  <div className="campoInserimentoMezzo">
                      <label>Targa del Mezzo</label>
                      <input  type="text" value={targaMezzo} onChange={(e)=>setTargaMezzo(e.target.value.toUpperCase().replace(/\s+/g, ''))}></input>
                  </div>
                  <div className="campoInserimentoMezzo">
                      <label> Capo Squadra:</label> 

                      <select onChange={(e)=>setIdCapoSquadra(e.target.value)}>
                          <option >---</option>
                          {listaCapiSquadra.map((user)=>{
                                  return <option key={user.id} value={user.id}>{user.username}</option>
                              })}
                      </select>
                  </div>
                  <div className="campoInserimentoMezzo">
                      <label>Tipo di Mezzo e altre caratteristiche</label>
                      <input  type="text" value={tipoMezzo} onChange={(e)=>setTipoMezzo(e.target.value)}></input>
                  </div>

                  <div className="bottoneConferma" onClick={()=>controlliPreCreazione()}>MODIFICA</div>
                  <span className={`avviso ${avvisoModifica.className}`}>{avvisoModifica.text}</span>
            </div>}
            

        <div className="listaMezzoContainer"> 
            <div className="headSingolaMezzoContainer"> 
              <span className="targa">TARGA</span>
              <span className="capoSquadra">CAPO SQUADRA</span>
              <span className="loggato">LOGGATO</span>

              <span className="tipomezzo">TIPO/ALTRO</span>
            </div>
            {/* <span className="avviso">{avviso}</span> */}
            

            {listaMezzi.map((item)=>{
              /* se eliminata può essere ripristinata e viceversa, ma se è loggata non può essere modificata!!!! significa che è sul campo, e gli sto assegnando delle richieste!! */
              return <div className="singoloMezzoContainer" key={item.id} id={item.id} >
                      <span className="targa"><FontAwesomeIcon icon={faTruckPickup} className="iconaTruckMezzi"></FontAwesomeIcon> {item.targaMezzo}</span>
                      <span className="capoSquadra">{item.user.username}</span>
                      {item.user.logged&&<span className="loggato"> LOGGATO </span>} 
                      {!item.user.logged&&<span className="loggato"> NON LOGGATO </span>} 
                      <span className="tipomezzo">{item.tipoMezzo}</span>   

                      {!item.deleted&& <div className="mezziButtonsNotDeleted eliminaMezzo" onClick={(e)=>handleDelete(e)}>Disabilita</div>}
                      {item.deleted&& <div className="mezziButtonsDeleted eliminaMezzo" onClick={(e)=>handleDelete(e)}>Ripristina</div>} 
                      <div className="bottoneModificaMezzo" onClick={()=>{setModificaMezzo(!modificaMezzo); setMezzoDaModificare(item)}}>Modifica</div>
                  </div>
            })}
            {loading&&<div className="loaderContainer"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>}
            <br/>
            <p> Se un mezzo viene disabilitato, non apparirà nella lista dei mezzi che possono essere assegnati alle richieste. </p>
        </div>
        </>
        
    )
  }

export default MostraMezzi;